(function(){
    /**
     * Обрабатывает строку с использование string substitution: format("Test: %s - value", "var")
     * @returns отформатированная строка (напр., "Test: var - value")
     */
    function format() {
        var string = arguments[0];
        for (var i = 1; i < arguments.length; i++) {
            string = string.replace(/%s/, arguments[i]);
        }
        return string;
    };
    function formatError(error) {
        var stack = error.stack;
        if (stack) {
            var msg = error.message;

            var messageStartIndex = stack.indexOf(msg);
            if (messageStartIndex !== -1) {
                var i = messageStartIndex + msg.length;
                msg = stack.substring(0, i);
                stack = stack.substring(i + 1);
            }

            var formattedStack = stack
                .split("\n")
                .filter(function (item, index) {
                    return !(index === 0 || item.includes('chrome-extension'));
                })
                .join("\n");

            error.stack = msg + "\n" + formattedStack;
        }
        return error;
    }
    var originalConsoleObject = {
        log: window.console.log,
        warn: window.console.warn,
        error: window.console.error
    };

    Object.assign(window.console, {
        info: function () {
            OK.Tracer.log('[Info]: ' + Array.from(arguments).join(' '));
            originalConsoleObject.log.apply(this, arguments);
        },
        warn: function () {
            OK.Tracer.log('[Warning]: ' + Array.from(arguments).join(' '));
            originalConsoleObject.warn.apply(this, arguments);
        },
        error: function () {
            if (arguments.length > 0) {
                var item = arguments[0];
                var error;
                if (item instanceof Error) {
                    error = item;
                } else if (typeof item === 'string') {
                    var args = Array.from(arguments);
                    error = formatError(
                        new Error(item.includes('%s')
                            ? format.apply(this, args)
                            : args.join(' ')
                        )
                    );
                } else {
                    error = formatError(new Error(JSON.stringify(item)));
                }
                OK.Tracer.error(error);
            }
            originalConsoleObject.error.apply(this, arguments);
        }
    });
}());


/* Odnoklassniki javascript file */
var OK = OK || {};
OK.cnst = {
    pageCtx : window.pageCtx,
    staticUrl : window.pageCtx['staticResourceUrl']
};

OK.navigation = {
    SPLITER: "<!--&-->",
    HEADER: "Rendered-Blocks",
    redirect: false //API stub that is supposed to be overridden
};

OK.getCurrentStateLink = OK.getCurrentStateLink || function() {
    // NavigationFactory ещё не загрузился
    return '/dk?' + window.pageCtx.state.replace(/&amp;/g, '&');
};

OK.fn = (function () {
    return {
        /**
         * Пустая функция
         */
        empty: function () {},
        /*
        * Включен ли дебаг. Этот элемент выставлен на дэве и тесте
        */
        isDebug: function () {
            return document.getElementById("__gwtd__m") != null;
        },

        /**
         * Возвращает функцию у которой первые params.length параметров зафиксированы в значения равные params.
         * @param targetFn целевая функция
         * @param params зафиксированные параметры
         * @param context лочим контекст для делегата (нужно для правильного делегирования в gwt)
         */
        delegate: function (targetFn, params, context) {
            return function () {
                var args = Array.prototype.slice.call(arguments);  // копируем текущие аргументы в массив
                var targetParams = (params || []).concat(args); // объединяем закаренные и текущие аргументы
                return targetFn.apply(context || this, targetParams); // дергаем целевую функцию
            };
        },

		deferred: function (context, fnName, args) {
			var args = Array.prototype.slice.call(args);  // копируем текущие аргументы в массив
			return function () {
				var targetFn = context[fnName];
				return targetFn.apply(context, args); // дергаем целевую функцию
			};
		},

		/**
         * Обертка, логирующая все ошибки при вызове targetFn. В случае ошибки вызывает p_loggerFn || OK.logging.currentLoggerFn
         * с единственным параметром native js exception.
         *
         *
         * @param p_targetFn целевая функция
         * @param p_loggerFn (optional) функция, которой делегируется логгирование ошибки. Если не указано, то текущий логгер из OK.logging
         *
         */
        logging: function (p_targetFn, p_loggerFn) {
            var loggerFn = p_loggerFn || OK.logging.currentLoggerFn || OK.fn.empty; // делегат логирования
            return function () {
                try {
                    return p_targetFn.apply(this, arguments);   // дергаем целевую функцию
                } catch (ex) {
                    OK.Tracer.error(ex);
                    loggerFn.call(window, ex);
                }
            }
        }
    };
})();

OK.hookModel = OK.hookModel || {
    getHookById: OK.fn.empty,
    removeHookById: OK.fn.empty,
    getNearestBlockHookId: OK.fn.empty,
    /**
     * При инициализации gwt этот метод будет переопрелен для работы с HookModel.
     * @param {String} blockId
     * @param {String} content
     */
    setHookContent: function(blockId, content, _) {
        if (blockId == "HeadMeta") {
            processNewMetaTags(content)
        } else {
            var el = document.getElementById("hook_Block_" + blockId);
            if (el) {
                if (require.defined('OK/capture')) {
                    require('OK/capture').deactivate(el);
                }
                el.innerHTML = content;
                require(['OK/capture'], function (capture) {
                    capture.activate(el);
                });
            }
        }

        function processNewMetaTags(content) {
            var head = document.getElementsByTagName('head')[0];
            var headElements = head.childNodes;
            var deletionMode = false;
            for (var i = 0; i < headElements.length; ) {
                el = headElements[i];
                if (el.nodeType == 8 && el.textContent.indexOf("META START") > -1) {
                    deletionMode = true;
                }
                if (deletionMode) {
                    head.removeChild(el);
                } else {
                    i++;
                }
                if (el.nodeType == 8 && el.textContent.indexOf("META END") > -1) {
                    break;
                }
            }
            if (deletionMode) { // старые мета-тэги нашли и удалили, теперь вставим новые
                head.insertAdjacentHTML('afterbegin', content);
            }
        }
    },
    captureBlockHook: OK.fn.empty,
    isHookEmpty: function(hookId) {
        var hook = this.getHookById(hookId);
        return !hook || !hook.element || !hook.element.childNodes || !hook.element.childNodes.length;
    }
};

OK.clickLog = OK.clickLog || {
    getCounter: OK.fn.empty,
    incCounter: OK.fn.empty,
    decCounter: OK.fn.empty
};

/**
 * Хранит текущий контекст логирования ошибок.
 *
 * Основной юзкейс
 * Пусть у нас есть функция которая выполняется в таймере: <code>fn = function () {...}; window.setTimeout(fn, 100);</code>
 * Тогда любые исключения произошедшие внутри этой функции не будут логироваться. Чтобы включить логирование достаточно
 * обернуть целевую функцию <code>fn</code> с помощью <code>OK.fn.logging</code>: <code>window.setTimeout(OK.fn.logging(fn), 100)</code>.
 * Теперь все ошибки будут
 * логироваться с помощью логгера установленного методом <code>setUp</code> (обычно вызывается в процесс работы JavascriptHook)
 *
 */
OK.logging = (function () {
    return {
        /*
        * Является ли текущий агент - селениум ботом (проверяется наличием куки "_okSat" в GWTUtils)
        */
        isSeleniumBot : false,
        /**
         * Дефолтный текущий логгер
         */
        currentLoggerFn: OK.fn.empty,

        /**
         * Установить дефолтный текущий логгер ошибок
         * @param p_loggerFn
         */
        setUp: function (p_loggerFn) {
            OK.logging.currentLoggerFn = p_loggerFn;
        },
        /**
         * Сбросить дефолтный текущий логгер
         */
        tearDown: function () {
            OK.logging.currentLoggerFn = OK.fn.empty;
        },
        alert: function(s) {
            if (OK.fn.isDebug()) {
                alert(s);
            }
		},
		info: function () {
            OK.Tracer.log('[Log]: ' + Array.from(arguments).join(' '));
			if (OK.fn.isDebug()) {
				try { console && console.log.apply(console, arguments); } catch (ex) {}
			}
		},
		logger: {  // Implemented in one.widget.test.client.widgets.uitest.Logger

            queue: [],
            replayQueue: function() {
                var i, q = this.queue;

                // cache q.length to prevent infinite looping
                // if any logging method hasn't been overridden
                var qLen = q.length;

                for(i = 0; i < qLen; i++) {
                    q[i].call();
                }
				this.queue = [];
            },

            // API stub defined in one.widget.test.client.widgets.uitest.Logger

            /** API stub that is supposed to be overridden */
			error: function (whereHappened, message) {
				this.queue.push(OK.fn.deferred(this, 'error', arguments));
            },

			/** API stub that is supposed to be overridden */
            success: function (operation, param1, param2, rawData) {
				this.queue.push(OK.fn.deferred(this, 'success', arguments));
            },

            force: function (operation, param1, param2) {
                this.queue.push(OK.fn.deferred(this, 'force', arguments));
            },

			/** API stub that is supposed to be overridden */
            duration: function(operation, duration, param1, param2) {
                this.queue.push(OK.fn.deferred(this, 'duration', arguments));
            },

			/** API stub that is supposed to be overridden */
			clob: function (operation, clob, param1, param2) {
				this.queue.push(OK.fn.deferred(this, 'clob', arguments));
			},

			/** API stub that is supposed to be overridden */
            trace: function (operation, info, applicationPartWhereExcCaught, errorName) {
				this.queue.push(OK.fn.deferred(this, 'trace', arguments));
			},

            /** API stub that is supposed to be overridden */
			raw: function (operation, data) {
				this.queue.push(OK.fn.deferred(this, 'raw', arguments));
			},

            errorEx: function (exception, applicationPartWhereExcCaught, param2, customOperation) {
                var operation = 'error';
                if (customOperation) {
                    operation = customOperation;
                }
                var clob = "Fatal JavaScript Error, please see log:\n" +
                        "exception ='" + exception + "'\n" +
                        "stacktrace=" + exception.stack;
                this.clob(operation, clob, applicationPartWhereExcCaught, param2);
            },

            /**
             * Метод, позволяющий отправить ошибку в Graylog.
             *
             * Фильтр:
             * @see https://graylog.service.local.odkl.ru/search?q=facility%3A+odnoklassniki-web+AND+%22client.error%22
             */
            saveAsClientError: function (exception) {
                function func(cfg) {
                    if (!cfg.isLogClientErrorsToGraylogEnabled) {
                        return;
                    }
                    var operation = 'undefined-error';
                    var data = {
                        url: window.location.href,
                    }
                    var stack = exception.stack;
                    if (stack) {
                        var stackLowerCase = stack.toLowerCase();
                        if (stackLowerCase.includes('react')) {
                            operation = 'react-error';
                        } else if (stackLowerCase.includes('res/js')) {
                            operation = 'modules-error';
                        } else if (stackLowerCase.includes('web/gwt')) {
                            operation = 'gwt-error';
                        }
                    }

                    this.errorEx(exception, "client.error", data.url, operation);
                }

                var funcWithContext = func.bind(this);

                var moduleId = 'OK/pms!debugConfiguration';
                if (require.defined(moduleId)) {
                    /* Если модуль определён, получим его из контекста Require и сразу выполним функцию активации */
                    funcWithContext(require(moduleId));
                } else {
                    /* Если модуль НЕ определён, выполним процедуру загрузки и проведём отложенную активацию */
                    require([moduleId], funcWithContext);
                }
            },


            /**
             * Округляем среднее арифметическое от duration:
             *  — 0 .. 1000 округляем до сотен
             *  — 1000 .. 10000 округляем до тысяч
             *  — 10 000 ... 60 000 округляем до десятков тысяч
             * значение больше 60 000 мс считаем аномально долгим
             *
             * 49 → 0
             * 50 → 100
             * 1499 → 1000
             * 1500 → 2000
             * 14999 → 10000
             * 15000 → 20000
             *
             * @param duration в миллисекундах
             */
            durationScalar: function(operation, param, duration) {
                if (duration != null && duration < 60000) {
                    var place = 10000; // округляем до тысячей: 14999 → 10000, 15000 → 20000
                    if (1000 <= duration && duration < 10000) {
                        place = 1000; // округляем до тысячей: 1499 → 1000, 1500 → 2000
                    } else if (duration < 1000) {
                        place = 100; // округляем до сотен: 49 → 0, 50 → 100
                    }
                    duration = Math.round(duration/place)*place;
                } else {
                    duration = 60000;
                }

                this.success(operation, param, ""+duration); // ""+duration конвертируем в стринг, чтобы в GWT-классе Logger не падала ошибка
            }
        },
        repository : (function(){
            var agg = {}, plain = [];

            function isArray(a) {
                return a && Object.prototype.toString.call(a) === '[object Array]';
            }

            function isObject(a) {
                return a && Object.prototype.toString.call(a) === '[object Object]';
            }

            function isDefined(a) {
                return a !== undefined;
            }

            function toZeroFilledArray(n) {
                var t = [];
                while(n) t[--n] = 0;
                return t;
            }

            function prepareData(obj) {
                var str = JSON.stringify(obj);
                if (!str || str.length <= 2) {
                    return null;
                }
                return encodeURIComponent(str);
            }

            function getAggData() {
                var r = prepareData(agg);
                agg = {};
                return r;
            }

            function getPlainData() {
                var r = prepareData(plain);
                plain = [];
                return r;
            }

            function savePlain(operation, param1, param2, duration, info, clob, rawData) {
                var o = {'o' : operation};
                param1 && (o['p1'] = param1);
                param2 && (o['p2'] = param2);
                duration && (o['d'] = duration);
                info && (o['i'] = info);
                clob && (o['c'] = clob);
                rawData && (o['r'] = rawData);
                plain.push(o);
            }

            function saveAggregated(arr) {
                saveAggregated0(agg, arr);
            }

            function saveAggregated0(root, arr) {
                var lvl = arr.pop(), result = root;
                if (typeof lvl === 'string') {
                    result = !result ? {} : !isObject(result) ? {'n/a': result} : result;
                    result[lvl] = saveAggregated0(result[lvl], arr);
                } else if (isObject(result)) {
                    result['n/a'] = saveAggregated0(result['n/a'], [lvl]);
                } else if (isArray(result)) {
                    result.push(lvl? lvl : 0);
                } else if (lvl) {
                    result = toZeroFilledArray(result);
                    result.push(lvl); // duration
                } else {
                    result = 1 + (result || 0);
                }
                return result;
            }

            return {
                addLog : function(operation, duration, info, clob, rawData, param1, param2) {
                    if (info || clob || rawData) {
                        savePlain(operation, param1, param2, duration, info, clob, rawData);
                    } else {
                        var arr = [];
                        isDefined(duration) && arr.push(duration);
                        isDefined(param2) && arr.push("" + param2);
                        isDefined(param1) && arr.push("" + param1);
                        arr.push("" + operation);
                        saveAggregated(arr);
                    }
                },
                getRequestData : function() {
                    var params = [];
                    var aggData = getAggData();
                    var plainData = getPlainData();

                    if (aggData) {
                        params.push('a=' + aggData);
                    }

                    if (plainData) {
                        params.push('p=' + plainData);
                    }

                    if (params.length > 0) {
                        // Доклеиваем к каждому запросу `StatId`, чтобы иметь возможность его извлечь на сервере.
                        // @see https://jira.odkl.ru/browse/ECOM-1791
                        params.push(OK.STAT_ID_PARAMETER + '=' + OK.getStatId());

                        return params.join('&');
                    }

                    return null;
                }
            };
        }())
    };
})();
OK.logger=OK.logging.logger;
OK.logger.repository = OK.logging.repository;
define('OK/logger', OK.logger);

OK.onload = (function(_wnd) {
    var onloadCallbacks = [],
    callAsync = true,
    addCallback = function(onloadCallback, requiredScripts) {
        if (requiredScripts != null && requiredScripts.length > 0) {
            var originalCallback = onloadCallback;
            onloadCallback = OK.fn.delegate(
                OK.loader.use,
                [originalCallback, requiredScripts]
            );
        }

        if (callAsync) {
            onloadCallbacks.push(onloadCallback);
        } else {
            try {
                onloadCallback.call(_wnd);
            } catch(e) {
                OK.logging.alert("Onload callback error after immidiate execute: " + e.message);
            }
        }
    },
    fire = function() {
        callAsync = false;
        for (var i = 0; i < onloadCallbacks.length; i++) {
            var callback = onloadCallbacks[i];
            try {
                callback.call(_wnd);
            } catch(e) {
                OK.logging.alert("Onload callback error after startup execute: " + e.message);
            }
        }
        onloadCallbacks.length = 0;
    };
    return {
        addCallback : addCallback,
        fire : fire
        };
})(window);

OK.loader = (function() {
    var
            _STRING = null,
            COMMA = null,
            READY_STATUS_COMPLETE = null,
            READY_STATUS_LOADED = null,
            okConstants = null,
            staticUrl = null,
            pageContext = null,
            globalScriptsArray = {},
            d = null,
            head = null,
            isVariablesInited = false,
            callbackQueue = {},
            initVariables = function() {
                if (isVariablesInited) {
                    return;
                }
                isVariablesInited=true;
                _STRING = "string";
                COMMA = ",";
                READY_STATUS_COMPLETE = "complete";
                READY_STATUS_LOADED = "loaded";
                okConstants = OK.cnst;
                staticUrl = okConstants.staticUrl;
                pageContext = okConstants.pageCtx;
                d = document;
                head = d.getElementsByTagName("head")[0];
                OK.util.extend(globalScriptsArray, {
                    // global script definition list
                    'abstractHooks': {src : staticUrl + getPageContextValue('abstractHooksSrc')},
                    'OKCustomJs' : {src : staticUrl + getPageContextValue('bottomJsSrc')},
                    'OKTextarea': {src: staticUrl + getPageContextValue('textareaJsSrc')},
                    'OKPhotoUploader' : {src : staticUrl + getPageContextValue('photoUploaderJsSrc')},
                    'OKRegJs' : {src : staticUrl + getPageContextValue('regJsSrc')},
                    'cdnNode': {src: getPageContextValue('cdnNodeSrc')},
                    'OKPromo': {src: staticUrl + getPageContextValue('promoAppJsSrc')},
                    'OKGifts' : {src : staticUrl + getPageContextValue('giftsJsSrc')},
                    'OKAppEdit' : {src : staticUrl + getPageContextValue('appEditJsSrc')}
                });
            },
            getPageContextValue = function(key) {
                if (pageContext != null && key in pageContext) {
                    return pageContext[key];
                }
                return "";
            },
            insertScript = function(scriptObj, callback) {
                var s = d.createElement('script');
                scriptObj.waiting = true;
                s.onload = s.onreadystatechange = function() {
                    var readyState = s.readyState;
                    if ((readyState && readyState != READY_STATUS_COMPLETE && readyState != READY_STATUS_LOADED)) return;
                    s.onload = s.onreadystatechange = null;
                    scriptObj.ok = true;
                    try {
                        callback.call(this);
                    } catch(e) {
                        OK.logging.alert("Loader callback error after script loaded: " + e.message);
                    }
                };
                s.async = 1;
                s.src = scriptObj.src;
                head.appendChild(s);
            },
            fetchScript = function(globalScriptObj) {
                //fetch script only if it is not ready and not in progress
                if (!globalScriptObj.ok && !globalScriptObj.waiting) {
                    insertScript(
                            globalScriptObj,
                            //callback functions after script is loaded
                            function() {
                                //walk thru a loop of stored callbacks
                                //top loop is loop thru callback kies (they are requested scripts as contatenated string, e.g. "jQuery, scriptBottom, swfobject" )
                                for (var key in callbackQueue) {
                                    //middle loop is thru different calls with the same key
                                    for (var i = 0; i < callbackQueue[key].length; i++) {
                                        var currentCallback = callbackQueue[key][i];
                                        //check if current callback is not removed from queue
                                        if (currentCallback == null) {
                                            continue;
                                        }
                                        var scriptsToLoadArray = currentCallback.srcArr;
                                        var allScriptsForCurrentCallbackAreLoaded = 1;
                                        //bottom loop is thwu array of requested scripts to check if all scripts for current callback are loaded
                                        for (var j = 0; j < scriptsToLoadArray.length; j++) {
                                            //if current requested script is not loaded - mark it
                                            if (!globalScriptsArray[scriptsToLoadArray[j]].ok) {
                                                allScriptsForCurrentCallbackAreLoaded = 0;
                                            }
                                        }
                                        //check if all scripts for current callback are loaded
                                        if (allScriptsForCurrentCallbackAreLoaded) {
                                            //execute callback
                                            try {
                                                callbackQueue[key][i].clbk.call();
                                            } catch(e) {
                                                OK.logging.alert("Loader queue callback error after all script loaded " + "["+scriptsToLoadArray.join()+"]: " + e.message + "\n" + e.stack);
                                            }
                                            //remove it from queue
                                            callbackQueue[key][i] = null;
                                        }
                                    }

                                }
                            })
                }
            },
            execute = function(scriptsToLoadArray, callback) {
                initVariables();
                var passedScriptsAreValid = 1,
                        allScriptAreLoaded = 1,
                        globalScriptObj = null;
                if (typeof scriptsToLoadArray == _STRING) {
                    scriptsToLoadArray = scriptsToLoadArray.split(COMMA);
                }
                // Load jQuery with requirejs
                var jqIndex = Array.prototype.indexOf.call(scriptsToLoadArray, 'jQuery'), // IE8 умеет только так
                    deps = [];
                if (jqIndex >= 0) {
                    scriptsToLoadArray.splice(jqIndex, 1);
                    deps.push('jquery');
                }
                var requireCallback = function() {
                    //here we check if all requested scripts are defined in _globalScriptsArray_
                    for (var i = 0; i < scriptsToLoadArray.length; i++) {
                        var scriptKey = scriptsToLoadArray[i];
                        globalScriptObj = globalScriptsArray[scriptKey];
                        if (!globalScriptObj) {
                            //script is not defined - exit
                            passedScriptsAreValid = 0;
                            allScriptAreLoaded = 0;
                            break;
                        }
                        if (!globalScriptObj.ok) {
                            //any valid script is not loaded yet
                            allScriptAreLoaded = 0;
                        }
                    }

                    if (!passedScriptsAreValid) {
                        //some script is not defined - exit
                        return;
                    }

                    if (allScriptAreLoaded) {
                        //all scripts are loaded already - simply execute callback
                        try {
                            callback.apply();
                        } catch(e) {
                            OK.logging.alert("Loader callback error after immediate execute " + "["+scriptsToLoadArray.join()+"]: " + e.message + "\n" + e.stack);
                        }
                        return;
                    }
                    //use concatenated scripts as key to store callback for future execution (when all requested scripts are loaded)
                    var callbackKey = scriptsToLoadArray.join(COMMA);
                    if (!callbackQueue[callbackKey]) {
                        //create new callback storage if it doesnt exist
                        callbackQueue[callbackKey] = [];
                    }
                    //store callback for future execution
                    callbackQueue[callbackKey].push(
                            {
                                //scriptsToLoadArray
                                srcArr : scriptsToLoadArray,
                                //function to call
                                clbk : callback
                            }
                    );
                    //fetch all requested scripts in a loop
                    for (i = 0; i < scriptsToLoadArray.length; i++) {
                        //get script object from global defenition list
                        globalScriptObj = globalScriptsArray[scriptsToLoadArray[i]];
                        //try to fetch script
                        fetchScript(globalScriptObj);
                    }
                };

                if (deps.length === 0) {
                    requireCallback();
                } else if (deps.length === 1 && require.defined(deps[0])) {
                    requireCallback(require(deps[0]));
                } else {
                    require(deps, requireCallback);
                }
            },
            executeRequire = function(/* string */ module, /* function(module) */ callback, /* function */ onError) {
                if (require.defined(module)) {
                    callback(require(module)); //synchronously execute
                } else {
                    require([module], callback, onError);
                }
            },
            register = function(scripts) {
                OK.util.extend(globalScriptsArray, scripts);
            };
    return {
        /**
         * Asynchronously loads all requered scripts and then executes callback
         * @param scripts Array or String of all requered scripts. String must be joined with comma (e.g. "jQuery,swfobject"). Array as usual: ["jQuery","swfobject"]
         * @param callback callback function to be executed when all required scripts are loaded
         */
        use: execute,
        /**
         * Synchronously execute callback if module just loaded, else asynchronously load module and execute callback
         * @param require requireJs module name
         * @param callback callback function to be executed. pass module to parameter
         * @param onError onError function to be executed if was error on load
         */
        execRequire : executeRequire,
        register: register
    };
})();

OK.util = (function(w, d) {

    var htmlClassList = d.documentElement.classList;

    return {
        isGecko: function () {
            return htmlClassList.contains('gecko');
        },
        isWebkit: function () {
            return htmlClassList.contains('webkit');
        },
        isSafari: function () {
            return htmlClassList.contains('mac-saf');
        },
        isIEMetro: function () {
            var result = false;
            if (w.navigator.msPointerEnabled) {
                try {
                    // IE Metro does not support ActiveX
                    new ActiveXObject("htmlfile");
                } catch (e) {
                    // http://stackoverflow.com/a/24622641
                    result = (w.screenY === 0 && (w.innerHeight+1) !== w.outerHeight);
                }
            }
            return result;
        },
        counterId: 0,

        extend: function(to, from) {
            to = to || {};
            for (var i in from) {
                if (from.hasOwnProperty(i)) {
                    to[i] = from[i];
                }
            }
            return to;
        },

        clean: function(obj) {
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    obj[i] = null;
                }
            }
        },

        keys: function(hash) {
            var keys = [];
            for (var i in hash) {
                if (hash.hasOwnProperty(i)) {
                    keys.push(i);
                }
            }
            return keys;
        },

        isHighDensity: function () {
			return (
			(
				w.matchMedia && (
					w.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches
					|| w.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches
				)
			)
            ||
            (
                w.devicePixelRatio && w.devicePixelRatio > 1.3
            ));
        },

        setHighDensityCookie: function (cookieName) {
			var cookie = cookieName + "=";
			if (OK.util.isHighDensity()) {
				cookie += "h;max-age=31536000;path=/";
			} else {
			    cookie += ";expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
            }
            d.cookie = cookie;
		},


        plurals: function(n) {
            n = Math.abs(n) % 100;
            if (n > 10 && n < 20) {
                return 5;
            }
            var lastDigit = n % 10;
            if (lastDigit > 1 && lastDigit < 5) {
                return 2;
            }
            if (lastDigit == 1) {
                return 1;
            }
            return 5;
        },

        /**
         * Parse OK-style JSON - remove html comments marks, e.t.c.
         *
         * @deprecated Скорее всего вам нужен метод parseJsonCorrected()
         * @param {String} json JSON String
         * @return {*} parsed json
         */
        parseJSON: function(json) {

            var COMMENT_START = '<!--',
                    COMMENT_START_LEN = COMMENT_START.length,
                    COMMENT_END_LEN = '-->'.length;

            if (json) {
                if (json.indexOf(COMMENT_START) === 0) {
                    json = json.substring(COMMENT_START_LEN, json.length - COMMENT_END_LEN);
                }

                return JSON.parse(json);
            }
        },

        /**
         * Парсит содержимое елемента, которое было построено через вызовы:
         * one.app.community.dk.gwt.hook.server.HookServerAdapter#createSerializableData()
         * one.app.community.dk.gwt.hook.server.HookServerAdapter#createSerializableDataText()
         * @param {string} json
         * @return {{}|null}
         * @throws SyntaxError
         */
        parseJsonCorrected: function(json) {
            var COMMENT_START = '<!--',
                COMMENT_END = '-->',
                COMMENT_START_LEN = COMMENT_START.length,
                COMMENT_END_LEN = COMMENT_END.length;

            if (!json) {
                return null;
            }

            if (json.indexOf(COMMENT_START) === 0) {
                json = json.substring(COMMENT_START_LEN, json.length - COMMENT_END_LEN).replace(/\\u0026#45;\\u0026#45;/g, '--');

                if (!json.length) {
                    return null;
                }
            }

            return JSON.parse(json);
        },

        nextId: function() {
            return this.counterId++;
        },

        /**
         * Calculates element offset relative to document.
         * @param {Element} element
         * @return {{left: number, top: number}}
         */
        getOffset: function(element) {

            var offset = {left: 0, top: 0};

            do {
                offset.left += element.offsetLeft;
                offset.top += element.offsetTop;
            } while (element = element.offsetParent);

            return offset;
        },

        is4ColumnActive: function () {
            return window.matchMedia("screen and (min-width: 1274px)").matches;
        },

        /**
         * @param {XMLHttpRequest} xhr
         */
        processCssHeaders: function (xhr) {
            return new Promise(function (resolve) {
                var cssToLoad = xhr.getResponseHeader('required-css');

                if (cssToLoad) {

                    var cssRequire = [];

                    cssToLoad.split(',').forEach(function (css) {
                        if (css) {
                            cssRequire.push('OK/css-loader!' + css);
                        }
                    });

                    if (cssRequire.length) {
                        require(cssRequire, resolve, resolve);
                        return;
                    }
                }

                resolve();
            });
        }
    }
}(window, document));

OK.favicon  = (function() {
    return {
        change: function(url) {
            var iconOriginal = document.getElementById("favicon");
            if(!iconOriginal) return;
            var favicon = iconOriginal.cloneNode(true);
            favicon.setAttribute("href", url);
            iconOriginal.parentNode.replaceChild(favicon, iconOriginal);
        }
    };
}());

OK.css = (function() {
    var setDisplay = function(idOrElement, isShow) {
            var element = (typeof idOrElement == "string") ? document.getElementById(idOrElement) : idOrElement;
            if (!element) {
                return;
            }
            element.style.display = isShow ? "block" : "none";
        };

    return {
        display: function(id, isShow) {
            setDisplay(id, isShow);
        },
        /**
         *
         * @param {string} el
         * @param {string} className
         */
        addClass: function(el, className) {
            var element = document.getElementById(el);
            if (!element) {
                return;
            }
            element.classList.add(className);
        },
        /**
         *
         * @param {string} el
         * @param {string} className
         */
        removeClass: function(el, className) {
            var element = document.getElementById(el);
            if (!element) {
                return;
            }
            element.classList.remove(className);
        }
    };
}());

OK.form = (function() {
    var
            formTagName = 'form',
            submit = function(element) {
                var parent = element.parentNode;
                while (parent && parent.tagName && parent.tagName.toLowerCase() != formTagName) {
                    parent = parent.parentNode;
                }
                if (parent && parent.submit) {
                    parent.submit();
                }
                return false;
            },
            selectCheckboxs = function(mainbox, boxname) {
                var elems = mainbox.form.elements;
                var elemnum = elems.length;
                for (i = 0; i < elemnum; i++)
                    if (elems[i].name.substr(0, boxname.length) == boxname) elems[i].checked = mainbox.checked;
            },
            inputActiveClass = "input__active";

    return {
        submit: function(element) {
            return submit(element);
        },
        selectCBxs: function(mainbox, boxname) {
            selectCheckboxs(mainbox, boxname);
        },
        placeholder: function(el, placeholder) {
            require(['jquery'], function() {
                var wrapper = placeholder.parent();
                var job = function() {
                    var value = el.attr('contentEditable') === 'true' ? el.html() : el.val();
                    var oldValue = el.data('val') || "";
                    if (value !== oldValue) {
                        if (value != "") {
                            wrapper.addClass(inputActiveClass)
                        } else {
                            wrapper.removeClass(inputActiveClass);
                        }

                        el.data('val', value);
                        clearInterval(interval)
                    }
                };
                var interval = setInterval(job, 10);
                el.bind('blur', function(){
                    clearInterval(interval);
                });
            });
        }
    };
}());

/**
 * Используется при запросах так:
 * var request = $.ajax({
 *       type: 'POST',
 *       url: [url], // insert here correct url
 *       data: {"gwt.requested": window.pageCtx.gwtHash},
 *       headers: {"TKN": OK.tkn.get()}
 * });
 */
OK.tkn = (function() {
    var
        token = '',
        set = function(t) {
            this.token = t;
        },
        get = function() {
            return this.token;
        };
    return {
        set : set,
        get : get
    };
}());

/**
 * @namespace Holds device-related helpers.
 */
OK.device = (function() {
    return {
        /**
         * @public
         * @type Boolean
         */
        isTouch: ("ontouchend" in window)
    };
}());

var flashVerForGWT = [0,0,0];
var okFlashVersion = [0,0,0];
var minimumFlashVersion = 9;
function displayFlashContainer() {
    var d = document;
    var flashDiv = d.getElementById("flashMainContainerDiv");
    if (flashDiv == null) {
        return;
    }
    var fDS = flashDiv.style;
    var oFDS = d.getElementById("flashOldFlashContainerDiv").style;
    var nFDS = d.getElementById("flashNoFlashContainerDiv").style;
    hide(fDS);
    hide(oFDS);
    hide(nFDS);
    try {
        var html5Used = flashDiv.className.indexOf("apiNoFlashWarning")!=-1 && typeof window.postMessage != 'undefined';
        if (!html5Used && okFlashVersion[0] < minimumFlashVersion) {
            if (okFlashVersion[0] == 0) {
                show(nFDS);
            } else {
                show(oFDS);
            }
        } else {
            show(fDS);
            if(flashDiv.className.indexOf("apiNoHide")==-1) {
                //do not try to show flash when there is popLayer
                //this tricky case happends when whole page is fully loaded (aka refresh) on application/game page after paying with virtual money.
                var popLayer = document.getElementById("hook_PopLayer_popLayer");
                if (popLayer != null) {
                    // for fDS visibility should be used - same as in FlashUtilities
                    fDS.visibility = "hidden";
                }
            }
        }
    } catch(e) {
        show(nFDS);
    }

    function show(s) {
        s.display = "block";
    }

    function hide(s) {
        s.display = "none";
    }

}

/*
 * Регистрируем версию флеш-плеера пользователя
 */

(function(win, nav) {
    try {
        var _NULL = null,
                UNDEF = "undefined",
                OBJECT = "object",
                SHOCKWAVE_FLASH = "Shockwave Flash",
                SHOCKWAVE_FLASH_AX = "ShockwaveFlash.ShockwaveFlash",
                FLASH_MIME_TYPE = "application/x-shockwave-flash",
                playerVersion = [0,0,0],
                d = _NULL,
                navPlugins = nav.plugins,
                _parseInt = parseInt,
                regexGroup = "$1";
        function getFlashActiveXObjectVersion() {
            try {
                var prefix = SHOCKWAVE_FLASH_AX + ".";
                for (var i = 11; i > 8; i--) {
                    try {
                        var flash = new ActiveXObject(prefix + i);
                        var version = flash.GetVariable("$version");
                        return version;
                    } catch(e) {
                    }
                }
                return _NULL;
            } catch(e) {
                return _NULL;
            }
        }
        if (typeof navPlugins != UNDEF && typeof navPlugins[SHOCKWAVE_FLASH] == OBJECT) {
            d = navPlugins[SHOCKWAVE_FLASH].description;
            var mimeTypes = nav.mimeTypes;
            if (d && !(typeof mimeTypes != UNDEF && mimeTypes[FLASH_MIME_TYPE] && !mimeTypes[FLASH_MIME_TYPE].enabledPlugin)) { // navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin indicates whether plug-ins are enabled or disabled in Safari 3+
                d = d.replace(/^.*\s+(\S+\s+\S+$)/, regexGroup);
                playerVersion[0] = _parseInt(d.replace(/^(.*)\..*$/, regexGroup), 10);
                playerVersion[1] = _parseInt(d.replace(/^.*\.(.*)\s.*$/, regexGroup), 10);
                playerVersion[2] = /[a-zA-Z]/.test(d) ? _parseInt(d.replace(/^.*[a-zA-Z]+(.*)$/, regexGroup), 10) : 0;
            }
        }
        else if (typeof win.ActiveXObject != UNDEF) {
            try {
                var d = getFlashActiveXObjectVersion();
                if (d) {
                    d = d.split(" ")[1].split(",");
                    playerVersion = [_parseInt(d[0], 10), _parseInt(d[1], 10), _parseInt(d[2], 10)];
                }
            }
            catch(e) {
            }
        }
        win.okFlashVersion = playerVersion;
        win.flashVerForGWT = playerVersion;
        OK.onload.addCallback(function() {
            require(['OK/cookie'], function(cookie) {
                cookie.setCookie("_flashVersion", playerVersion[0], 30, "/");
                if (playerVersion[0] > 0) {
                    cookie.setCookie("_flash7dVersion", playerVersion[0], 7, "/");
                }
            });
        });
    } catch(e) {
    }
})(window, navigator);

OK.il = {
    handlers: {},
    ah: function(handler) {
        if (handler && handler.handle && handler.id) {
            var id = handler.id;
            if (this.handlers[id] == null) {
                this.handlers[id] = [];
            }
            this.handlers[id][this.handlers[id].length] = handler;
        }
    },
    f: function(event, id, arg) {
        if (id && this.handlers[id]) {
            var length = this.handlers[id].length, handlers = this.handlers[id];
            for (var i = 0; i < length; i++) {
                var handler = handlers[i];
                handler.handle(event, arg);
            }
        }
    },
    rh: function(handler) {
        if (handler && handler.id) {
            var id = handler.id;
            if (this.handlers[id] == null || handler.handlerId == null) {
                return;
            }
            var length = this.handlers[id].length, handlers = this.handlers[id], handlerId = handler.handlerId, foundIndex = -1;
            for (var i = 0; i < length; i++) {
                var handler = handlers[i];
                if (handler.handlerId == handlerId) {
                    foundIndex = i;
                    break;
                }
            }
            if (foundIndex > -1) {
                this.handlers[id].splice(foundIndex, 1);
            }
        }
    }
};

OK.pf = {};

/**
 * Получаем ширину вскролла
 */
OK.scrollBar = function () {
    // #sc, #sch should be already on page
    OK.scrollBar.width = document.getElementById('sc').offsetWidth - document.getElementById('sch').offsetWidth;
};

/**
 * Выстовляем ширину для контента после того, как на странице загрузился скролл
 */
OK.setContentWidth = function () {
    var scrollWidth = OK.scrollBar.width;
    var windowWidth = window.innerWidth - scrollWidth;

    // Setting min-width equal 1000 cause result window width must be (1000 + scrollWidth) < 1024
    windowWidth = Math.max(1000, windowWidth);
    var topPanel = document.getElementById('topPanel');
    var bodySwitcher = document.getElementById('hook_Block_BodySwitcher');
    var mainContainer = document.getElementById('mainContainer');

    // there are pages where can be not topPanel and bodySwitcher
    if (bodySwitcher) {
        bodySwitcher.style.width = windowWidth + 'px';
    }
    if (topPanel) {
        topPanel.style.width = windowWidth + 'px';
    }

    var isLayoutOptimizationOn = Boolean(document.getElementsByClassName("layout-optimization")[0]);

    if (mainContainer && isLayoutOptimizationOn) {
        mainContainer.style.width = windowWidth + 'px';
    }
};

/**
 * Stop event propagation. Used to prevent click processing on element
 *
 * @param event
 * @return {Boolean}
 */
OK.stop = function(event) {
    event = event || window.event;
    if (event.type === 'click' && OK.clickLog.getCounter() > 0) {
        event['log-clicks'] = true;
        return false;
    }
    if (event.stopPropagation) {
        event.stopPropagation();
    } else {
        event.cancelBubble = true;
    }
    return false;
};

OK.hardStopEvent = function(event) {
    event = event || window.event;
    if (event.stopPropagation) {
        event.stopPropagation();
    } else {
        event.cancelBubble = true;
    }
    return false;
};


// был ли эвент обработан, но пропущен на верх из-за лог кликс.
OK.isStopped = function(event) {
    event = event || window.event;
    return event && event['log-clicks'];
};

/**
 * Способ получить target для события работающий во всех браузерах.
 */
OK.eventTarget = function(event) {
    var event = event || window.event;
    var target;
    if (event.target) {
        target = event.target;
    } else if (event.srcElement) {
        target = event.srcElement;
    } else {
        target = window.document;
    }
    if (target.nodeType === 3) {
        target = target.parentNode;
    }
    return target;
};

/**
 * Generate submit event for form element
 */
OK.submit = function(element) {
    var formElement = element;
    if (element.tagName.toLowerCase() !== 'form') {
        formElement = element.parentNode;
    }
    if( document.createEvent ) {
        var evt = document.createEvent('Event');
        evt.initEvent('submit', true, true);
        formElement.dispatchEvent(evt);
    } else if( document.createEventObject ) {
        var evt = document.createEventObject();
        formElement.fireEvent('onsubmit', evt);
    }
};

/**
 * Открывает доступ из js к UserClientCache.
 * Реализация будет подставлена после активации хука.
 * Если gwt нет (анонимка), то будет использоваться то, что есть...
 *
 * @see UserClientCacheHook.
 */
OK.userCache = { // it will be replaced by GWT.
    _users: [],
    getFromUserCache:function (userId) {
        return this._users[userId];
    },
    updateUserCache:function (usersMap) {
        for (var userId in usersMap) {
            this._users[userId] = usersMap[userId];
        }
    },
    getLinkOnUserAvatar:function(user) { return ''; }
};

/**
 * Обработчик не обработанных кликов по содержимому контейнера.
 * см. DivAction.html (elem != null)
 *
 * Обработчик клика по ссылке открытия леера топиков.
 * см. LinkAction.html (elem == null)
 */
OK.showTopicInLayerOnClick = function(elem, event, topicId, ownerType, activityId, closeTopicId, closeOwnerType, toBlockIndex) {
    if (elem) {
        for (var e = OK.eventTarget(event); e && e != elem; e = e.parentNode) {
            if (e.tagName == 'A') {
                return; // игнорируем клики по ссылкам внутри дива. ссылки сами разберутся что делать.
            }
        }
    }
    var layer = document.getElementById('mtLayer');
    if (layer) {
        require(['OK/MediaLayer'], function(mtLayer) {
            mtLayer.showTopicInLayer(topicId, ownerType, activityId,
                false,
                '', null, null,
                false,
                closeTopicId, closeOwnerType, false, toBlockIndex);
        });
        return OK.stop(event);
    }
};

/**
 * На вебе обращаемся к хистори только через этот интерфейс (из js).
 * На GWT через HistoryManager, который будет подменять эту реализацию (пока сам сюда не переедет).
 *
 * @type {{isHistorySupported: isHistorySupported, getState: getState, replaceState: replaceState, pushState: pushState, putItemToCache: putItemToCache, back: back}|*}
 */
OK.historyManager = OK.historyManager /*если гвт было быстрее...*/ || {

    // Пока не загрузилось GWT мы можем работать только с html5 history
    // (чтобы гвт могло потом прочитать текущий стейт).
    // Ожидается, что historyManager может потребоваться до загрузки гвт
    // только при прямом переходе по шортлинку.
    // В этом случае (если это ссылка на леер) будет один
    // или два вызова push\replace state и всё.
    isHistorySupported:function() {
       return window.history && window.history.pushState && window.history.replaceState;
    },

    getState: function () {
        var state = location.pathname + location.search;
        if (!state) {
            if (typeof __gwt_currentHistoryItem != 'undefined') {
                state = __gwt_currentHistoryItem || "/";
            } else {
                state = "/";
            }
        }

        return state;
    },

    replaceState: function (state){
        __gwt_currentHistoryItem = state;
        window.history.replaceState(state, document.title, state);
    },

    pushState: function (state){
        __gwt_currentHistoryItem = state;
        window.history.pushState(state, document.title, state);
    },

    _cache: {}, //даём шанс гвт забрать эти данные.
    putItemToCache: function (base, query){
        OK.historyManager._cache[base] = query;
    },

    back: function() {
        return window.history.back();
    }
};

OK.ntf = {
    close : OK.fn.empty,
    lock : OK.fn.empty
};

OK.configurations = (function() {
    var configStore = {};
    return {
        get: function (alias) {
            return new Promise(function(resolve) {
                var config = configStore[alias];
                if(config) {
                    resolve(config)
                } else {
                    require(['OK/utils/vanilla'], function (vanilla) {
                        vanilla.ajax({
                            type: 'GET',
                            url: '/web-api/pms?name=' + encodeURIComponent(alias),
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            dataType: 'json'
                        }).then(function(result) {
                            config = JSON.parse(result.response.data);
                            configStore[alias] = config;
                            resolve(config);
                        }, function () {
                            require(['OK/logger'], function(logger) {
                                logger.error('configurations.loading.error', alias);
                            });
                        })
                    });
                }
            });
        }
    };
})();

OK.afterWindowOnloadAttach = function(fn) {
    var deffered = function() {
        if(performance.timing.loadEventEnd > 0) {
            fn.call();
        } else {
            setTimeout(deffered, 100);
        }
    };

    if(performance.timing.loadEventStart > 0) {
        setTimeout(deffered, 10);
    } else {
        window.addEventListener("load", function(){
            setTimeout(deffered);
        });
    }
};

OK.sendBeacon = function(url, data) {
    var sendUsingImage = true;
    if(navigator.sendBeacon){
        var headers = {
            type: 'application/x-www-form-urlencoded'
        };
        var blob = new Blob([data], headers);
        if(navigator.sendBeacon(url, blob)) {
            sendUsingImage = false;
        }
    }

    if(sendUsingImage) {
        var blob = new Blob([data]);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
        var statId = OK.getStatId();
        if (statId) {
            xhr.setRequestHeader(OK.STAT_ID_HEADER, statId);
        }
        xhr.send(blob);
    }
};

// Заглушка в отсутствие ГВТ
OK.isStateRedesign = OK.isStateRedesign || function() {
    return false;
};

OK.getRedesignVersion = OK.getRedesignVersion || function() {
    return null;
};

// Внимание! Синхронизировать с one.app.community.dk.gwt.desktop.client.ClientFlag
OK.ClientFlag = {
    MONEY_SAVE_V2: 'ms',
    DEFERRED_CSS: 'dcss',
    MOVIE_POSTING_V2: 'mpv2'
};

function parseFlags(flags) {
    var result = {};
    (flags || '').split(/;/g).forEach(function (flag) {
        var parts = flag.split(':');
        if (parts.length === 2) {
            result[parts[0]] = parts[1];
        }
    });
    return result;
}

OK.isClientFlagEnabled = OK.isClientFlagEnabled || function(flag) {
    return parseFlags(OK.getClientFlags())[flag] === '1';
};

OK.getClientFlags = OK.getClientFlags || function() {
    return document.documentElement.getAttribute('data-client-state');
};

OK.setClientFlags = OK.setClientFlags || function(flags) {
    document.documentElement.setAttribute('data-client-state', flags);
};

OK.setClientFlag = OK.setClientFlag || function(flag, enabled) {
    var flags = parseFlags(OK.getClientFlags());
    flags[flag] = enabled ? '1' : '0';
    var result = Object.getOwnPropertyNames(flags).map(function(flag) {
        return flag + ':' + flags[flag];
    }).join(';');
    OK.setClientFlags(result);
};

OK.CLIENT_FLAGS_HEADER = 'X-Client-Flags';

OK.topPanelHeight = function() {
    var cl = document.documentElement.classList;

    if (cl.contains("small-toolbar") || cl.contains("smooth-hiding")) {
        return 48;
    }

    if (cl.contains("new-external-mrg-toolbar")) {
        return 85;
    }

    return 76;
};

OK.STAT_ID_HEADER = OK.STAT_ID_HEADER || 'X-Stat-Id';

OK.STAT_ID_PARAMETER = OK.STAT_ID_PARAMETER || 'statId';

OK.getStatId = OK.getStatId || function() {
    return '';
};

var OK = OK || {};
/** namespace для всех лееров */
(function () {

    var MODAL_OVERLAY_CLASSES = '.modal_overlay, .modal-new_close_ovr, .layer_ovr, .ic_close, #hook_Block_PromoMainLayer .layerPanelClose, .js-close-layer';

    // stack array
    var stack = [],
        isEscDown; // becomes "true" when "keydown" event fires

    var layersStack = [];
    var layersMaxSize = 1;

    var listeners = {};

    var globalListeners = new Set();

    var subscribe = function(layerId, callback) {
        if (!listeners.hasOwnProperty(layerId)) {
            listeners[layerId] = [callback];
        } else {
            listeners[layerId].push(callback);
        }
    };

    var unsubscribe = function(layerId, callback) {
        if (!callback) {
            listeners[layerId] = [];
        } else {
            if (!listeners[layerId]) {
                return;
            }
            var index = listeners[layerId].indexOf(callback);
            if (index > -1) {
                listeners[layerId].splice(index, 1);
            }
        }
    };

    var subscribeGlobal = function(callback) {
        globalListeners.add(callback);
    };

    var unsubscribeGlobal = function(callback) {
        globalListeners.delete(callback);
    };

    var _call = function(layerId, status, result) {
        var topLayerId = getTopLayerId();
        if (topLayerId) {
            OK.Tracer.setErrorKey('layerId', topLayerId);
        } else {
            OK.Tracer.removeErrorKey('layerId');
        }
        if (OK.fn.isDebug()) {
            console.log("layer._call('" + layerId + "', '" + JSON.stringify(status) + "', '" + JSON.stringify(result) + "') topLayer = " + JSON.stringify(topLayerId));
        }


        if (listeners.hasOwnProperty(layerId)) {
            //  Подпичики частенько отписываются на лету, что ломает порядок колбеков в listeners.
            //  Перед проходом надо создать копию, которая не сломается.
            var listenersCopy = [];
            var layerListeners = listeners[layerId];
            for (i = 0; i < layerListeners.length; i++) {
                listenersCopy[i] = layerListeners[i];
            }

            for (var i = 0, l = listenersCopy.length; i < l; i++) {
                try {
                    listenersCopy[i].call(null, status, result, layerId);
                } catch (e) {
                    // Do nothing
                }
            }
        }
        //  Подпичики частенько отписываются на лету, что ломает порядок колбеков в listeners.
        //  Перед проходом надо создать копию, которая не сломается.
        Array.from(globalListeners).forEach(function(cb) {
            cb.call(null, layerId, status, result);
        });
    };

    var clear = function() {
        layersStack = [];
    };

    var setMaxSize = function(count) {
        layersMaxSize = count;
    };

    var prepareUrl = function(url) {
        //Убираем маркер текущего коммента и направление листания
        return url.replace(/st\.(fwd|layer\.direction)\=.*?(&|$)/g, '');
    };

    var push = function(link) {
        if (layersMaxSize <= 1) {
            return;
        }
        link = prepareUrl(link);
        layersStack.push({link : link});
        var dif = layersStack.length - layersMaxSize;
        if (dif > 0) {
            layersStack.splice(0, dif);
        }
    };

    var replace = function(link) {
        if (layersMaxSize <= 1 || layersStack.length === 0) {
            return;
        }

        link = prepareUrl(link);
        layersStack[layersStack.length - 1] = {link : link};
    };

    var changeAttrs = function(marker, isForward) {
        if (layersStack.length === 0) {
            return;
        }
        //Эти параметры рассчитаны на DiscussionBlock, чтобы он открывал нужную страницу комментов
        var attrs = layersStack[layersStack.length - 1].link.indexOf('?') >= 0 ? '&' : '?';
        attrs += 'st.fwd=';
        marker = encodeURIComponent(marker);
        if (isForward) {
            attrs += 'on&st.lastelem=' + marker;
        } else {
            attrs += 'off&st.firstelem=' + marker;
        }
        layersStack[layersStack.length - 1].attrs = attrs;
    };

    var lastUrl = function() {
        if (layersStack.length === 0) {
            return '';
        }

        var layer = layersStack[layersStack.length - 1]
        return layer ? layer.link : '';
    }

    var pop = function() {
        if (layersMaxSize <= 1 || layersStack.length == 0) {
            return null;
        }
        layersStack.splice(layersStack.length - 1, 1);
        if (layersStack.length == 0) {
            return null;
        }
        var data = layersStack[layersStack.length - 1];
        var url = data.link;
        if (data.attrs) {
            url += data.attrs;
        }

        //Дропаем из истории леер, который собираемся открыть. Так как при открытии он снова будет помещен в стэк
        layersStack.splice(layersStack.length - 1, 1);
        if (typeof navigateOnUrlFromJS !== 'undefined') {
            navigateOnUrlFromJS(url);
        } else {
            require(['OK/PopLayerPhoto'], function(handler) {
                handler.handleOnNavigate(url);
            });
        }

        return url;
    };

    var startLoad = function () {
        require(['OK/LayersLoader'], function(LayersLoader) {
            LayersLoader.startLoad();
        });
    }

    var endLoad = function (forceEnd) {
        require(['OK/LayersLoader'], function(LayersLoader) {
            LayersLoader.endLoad(forceEnd);
        });
    }

    /**
     * register to stack. Binded from GWT
     * @param {{
     * layerName?: string,
     * deactivateFn?: Function,
     * hookInstance?: object,
     * preventCloseOnEsc?: boolean,
     * keyDownHandler?: Function,
     * keyUpHandler?: Function,
     * redirectAfterClose?: string,
     * shouldIgnoreA11y?: boolean,
     * // Не разрешаем скрытие лоадера автоматически. Управление состоянием передаём модулю, который прокинул true
     * withStopLayerLoader?: boolean
     * // Кастомный случай, когда нужно передать ивент сторонним элементам в верстке
     * withoutStopPropagation?: boolean
     * }} params
     */
    var open = function (params) {
        if (params.shouldIgnoreA11y) {
            registerWithoutAccessibility(params.layerName, params.deactivateFn, params.hookInstance, params.preventCloseOnEsc, params.keyDownHandler, params.redirectAfterClose, params.keyUpHandler, params.withStopLayerLoader, params.withoutStopPropagation);
        } else {
            register(params.layerName, params.deactivateFn, params.hookInstance, params.preventCloseOnEsc, params.keyDownHandler, params.redirectAfterClose, params.keyUpHandler, params.withStopLayerLoader, params.withoutStopPropagation);
        }
    };

    var registerWithoutAccessibility = function (layerName, deactivateFn, hookInstance, preventCloseOnEsc, keyDownHandler, redirectAfterClose, keyUpHandler, withStopLayerLoader, withoutStopPropagation) {
        OK.loader.execRequire('OK/ToolbarGrowl', function(m){
            m.hideAll();
        });

        // optional
        hookInstance = hookInstance || null;
        preventCloseOnEsc = preventCloseOnEsc || false;
        keyDownHandler = keyDownHandler || function () {};
        keyUpHandler = keyUpHandler || function () {};
        redirectAfterClose = redirectAfterClose || null;

        var newEl = {
            id: layerName,
            deactivateFn: deactivateFn,
            hookInstance: hookInstance,
            preventCloseOnEsc: preventCloseOnEsc,
            keyDownHandler: keyDownHandler,
            keyUpHandler: keyUpHandler,
            redirectAfterClose: redirectAfterClose,
            withoutStopPropagation: withoutStopPropagation,
        };

        var reRegister = false;
        // push new layer to stack
        for (var i = 0; i < stack.length; i++) {
            if (stack[i].id === newEl.id) {
                reRegister = true;
                stack.splice(i, 1);
                OK.logging.logger.success("layerManager", "reRegister", newEl.id);
            }
        }
        stack.push(newEl);

        // Start listening to shadow clicks and ESC's if stack isn't empty
        if (stack.length === 1 && !reRegister) {
            bind();
        }

        _call(newEl.id, true);

        toggleGlobalScrolling();
        OK.logging.logger.success("layerManager", "register", newEl.id);

        if (!withStopLayerLoader) {
            endLoad();
        }
    };

    /**
     * register to stack. Binded from GWT
     * @param {string} layerName
     * @param {Function?} deactivateFn
     * @param {object?} hookInstance
     * @param {boolean?} preventCloseOnEsc
     * @param {Function?} keyDownHandler
     * @param {string?} redirectAfterClose
     * @param {Function?} keyUpHandler
     * @param {boolean?} withStopLayerLoader - Не разрешаем скрытие лоадера автоматически. Управление состоянием передаём модулю, который прокинул true
     * @param {boolean?} withoutStopPropagation
     * @deprecated {@see open}
     */
    var register = function (layerName, deactivateFn, hookInstance, preventCloseOnEsc, keyDownHandler, redirectAfterClose, keyUpHandler, withStopLayerLoader, withoutStopPropagation) {
        registerWithoutAccessibility(layerName, deactivateFn, hookInstance, preventCloseOnEsc, keyDownHandler, redirectAfterClose, keyUpHandler, withStopLayerLoader, withoutStopPropagation);
        require(['OK/AccessibleModal'], function(accessibleModal) {
            accessibleModal.AccessibleModal.registerModal(layerName);
        });
    };

    function unregisterById(id, result) {
        for (var i = 0; i < stack.length; i++) {
            if (stack[i].id === id) {
                var el = stack[i];

                stack.splice(i, 1);

                if (el) {
                    // execute layer's deactivation callback
                    deactivate(el);

                    // when stack becomes empty we need to stop listening for any events
                    if (!stack.length) {
                        unbind();
                    }
                    toggleGlobalScrolling();

                    if (el.redirectAfterClose !== null) {
                        if (OK.navigation.redirect) {
                            OK.navigation.redirect(el.redirectAfterClose);
                        } else {
                            window.location.assign(el.redirectAfterClose);
                        }
                    }

                    _call(el.id, false, result);

                    require(['OK/AccessibleModal'], function(accessibleModal) {
                        accessibleModal.AccessibleModal.removeModal(el.id);
                    });

                    OK.logging.logger.success("layerManager", "unregister", el.id);
                } else {
                    OK.logging.logger.success("layerManager", "popFailed");
                }
            }
        }
    }

    // pop layer from stack
    var unregister = function (eventType, result) {
        endLoad(true);
        // do pop only if stack isn't empty
        if (stack.length) {

            // do pop only for those layers that listen to ESC's and shadow-clicks
            if (!stack[stack.length - 1].preventCloseOnEsc) {

                var el = stack.pop();

                if (el) {
                    // execute layer's deactivation callback
                    deactivate(el);

                    // when stack becomes empty we need to stop listening for any events
                    if (!stack.length) {
                        unbind();
                    }
                    toggleGlobalScrolling();

                    if (el.redirectAfterClose !== null) {
                        if (OK.navigation.redirect) {
                            OK.navigation.redirect(el.redirectAfterClose);
                        } else {
                            window.location.assign(el.redirectAfterClose);
                        }
                    }

                    _call(el.id, false, result);

                    require(['OK/AccessibleModal'], function(accessibleModal) {
                        accessibleModal.AccessibleModal.removeModal(el.id);
                    });

                    OK.logging.logger.success("layerManager", "unregister", el.id);
                    OK.logging.logger.success("layerManager", eventType, el.id); // логируем как закрыт леер: эск или клик в оверлей
                } else {
                    OK.logging.logger.success("layerManager", "popFailed");
                }
            } else {
                OK.logging.logger.success("layerManager", "escDisabledForLayer", stack[stack.length - 1].id);
            }
        } else {
            OK.logging.logger.success("layerManager", "stackEmpty");
        }
    };

    var toggleGlobalScrolling = function() {
        var STACK_IDS = ['posting_form', 'video_player', 'OldFeedPopupLayer'];
        var scrollingOn = !stack.length
            || (stack.length === 1  && (STACK_IDS.indexOf(stack[0].id) !== -1
                || stack[0].id.indexOf('dd-menu') === 0));

        //ХХХ: есть проблема:
        //
        // если какие-то лееры были _скрыты_ при открытии дискуссий,
        // а потом из дискуссий пользователь перешёл по какой-нибудь ссылке на стейт (н-р, профиль друга),
        // то дискуссии закроются, а 'oh' останется и в ленте не будет скролла.
        //
        // Это происходит, т.к. скрытые ЛЕЕРЫ никто специально НЕ УДАЛЯЕТ из layerManager'a (при закрытии дискуссий по doNotRestorePopLayer),
        // а сами они не всегда это делают\могут сделать.
        //
        // В качестве workaround'a добавлена проверка на 'modal_hook' https://jira.odkl.ru/browse/OL-39910.
        var modalHook = (stack.length == 1 && stack[0].id == 'modal_hook');
        if (modalHook) {
            var elem = document.getElementById("hook_Modal_popLayerModal");
            if (elem && elem.classList.contains('layer__disabled')) {
                scrollingOn = true;
            }
        }

        require(['OK/OhManager'], function (ohManager) {
            ohManager.switchScrolling(scrollingOn);
        });
    };

    function notify4246() {
        var fcb = OK.hookModel.getHookById("ForthColumnTopBannerInner");
        if (fcb) {
            fcb.handle();
        }
    }

    // bind nescessary listeners
    var bind = function () {

        OK.loader.use(['jQuery'], function () {
            var body = $("body");

            // bind shadow clicks and ESCs
            body
                .on("click.popLayers", MODAL_OVERLAY_CLASSES, function (e) {
                    unregister("shadowClick");

                    e.stopImmediatePropagation(); //как минимум, при закрытии модального диалога вызывается несколько обработчиков.
                })
                /*
                 OL-40370: Некорректно работает Esc. при закрытии видео в фулскрине
                 События keyup в win-браузерах проваливаются из полноэкранного режима flash в js. Т.е. при нажатии ESC
                 происходит выход из полноэкранного режима и одновременно закрытие леера проигрывателя.
                 При этом с keydown такой проблемы нет. Но событие keydown сгорает множество раз пока зажата клавиша.
                 Поэтому реализован "смешанный" вариант, использующий и keyup и keydown.
                 */
                .on("keydown.popLayers", function (e) {
                    var onTop = stack[stack.length - 1];
                    if (onTop) {
                        onTop.keyDownHandler.call(this, e);
                    }

                    if (e.keyCode === 27 && !isEscDown) {
                        isEscDown = true;
                        unregister("esc");

                        e.preventDefault(); // Opera & IE(?) cancel current xhr request on esc. Не хотим этого!
                        if (!onTop.withoutStopPropagation) {
                            e.stopPropagation();
                        }
                    }
                })
                .on("keyup.popLayers", function (e) {
                    var onTop = stack[stack.length - 1];
                    if (onTop) {
                        onTop.keyUpHandler.call(this, e);
                    }

                    isEscDown = false;

                    if (e.keyCode === 27) {
                        e.preventDefault(); // Opera & IE(?) cancel current xhr request on esc. Не хотим этого!
                    }
                    if (!onTop.withoutStopPropagation) {
                        e.stopPropagation();
                    }
                });
        });

        require(['OK/LayersEventBuses'], function (LayersEventBuses) {
            LayersEventBuses.ANY_OPENED.emit({}, this);
        });
        // скрыть баннер 4246
        notify4246();
    };

    // unbind listeners
    var unbind = function () {

        OK.loader.use(['jQuery'], function () {
            isEscDown = false;

            // unbind clicks and ESCs
            $("body").off(".popLayers");
        });

        require(['OK/ToolbarGrowl'], function(m) {
            m.showAny();
        });

        require(['OK/LayersEventBuses'], function (LayersEventBuses) {
            LayersEventBuses.ANY_CLOSED.emit(null, this);
        });

        // показать баннер 4246
        notify4246();
    };

    // take layer and execute it's deactivation function
    var deactivate = function (layer) {
        layer.deactivateFn.call(layer.hookInstance);
    };

    // remove layer from stack by name. Binded from GWT
    var removeWithoutAccessibility = function (id) {

        var el;

        for (var i = 0; i < stack.length; i++) {
            if (stack[i].id === id) {
                el = stack.splice(i, 1);

                if (el && el[0]) {
                    _call(el[0].id, false);
                }

                OK.logging.logger.success("layerManager", "unregister", id);
            }
        }

        if (!el) {
            OK.logging.logger.success("layerManager", "unregisterLayerNotFound", id);
        }

        // if stack becomes emty then stop listening to events
        if (!stack.length && el) {
            unbind();
        }

        toggleGlobalScrolling();
        endLoad();
    };

    var remove = function (id) {
        removeWithoutAccessibility(id);

        require(['OK/AccessibleModal'], function(accessibleModal) {
            accessibleModal.AccessibleModal.removeModal(id);
        });
    };

    var unregisterAll = function () {
        var stackLength = stack.length;
        for (var i = stackLength; i > 0; i--) {
            unregister("unregisterAll");
        }
    };

    // public method to guess if any layer is opened
    var isAnyLayerOpened = function () {
        return stack.length !== 0;
    };

    var isLayerOpened = function(layerKey) {
        if (stack) {
            for (var i = 0; i < stack.length; i++) {
                var layer = stack[i];
                if (layer.id === layerKey) {
                    return true;
                }
            }
        }
        return false;
    };

    var getTopLayer = function () {
        if (isAnyLayerOpened()) {
            return stack[stack.length - 1];
        }
        return null;
    };

    var getTopLayerId = function() {
        var topLayer = getTopLayer();
        return topLayer ? topLayer.id : null;
    };

    OK.Layers = {
        /**
         * @deprecated {@see open}
         */
        register: register,
        registerWithoutAccessibility: registerWithoutAccessibility,
        open: open,
        remove: remove,
        removeWithoutAccessibility: removeWithoutAccessibility,
        isAnyLayerOpened: isAnyLayerOpened,
        isLayerOpened: isLayerOpened,
        unregisterAll: unregisterAll,
        unregisterLast: unregister,
        unregisterById: unregisterById,
        stack: stack,
        push: push,
        pop: pop,
        lastUrl: lastUrl,
        changeAttrs: changeAttrs,
        clear: clear,
        replace: replace,
        setMaxSize: setMaxSize,
        onLayerShown: OK.fn.empty,
        onLayerHidden: OK.fn.empty,
        subscribe: subscribe,
        unsubscribe: unsubscribe,
        getTopLayer: getTopLayer,
        getTopLayerId: getTopLayerId,
        subscribeGlobal: subscribeGlobal,
        unsubscribeGlobal: unsubscribeGlobal,
        startLoad: startLoad,
        endLoad: endLoad
    };
})();


if (OK.historyManager.isHistorySupported() && !window.onpopstate) {
    window.onpopstate = function(e) {
        /**
         * https://developer.mozilla.org/ru/docs/Web/API/WindowEventHandlers/onpopstate
         * Браузеры по разному обрабатывают событие на странице загрузки. Chrome (prior to v34) и Safari всегда
         * генерируют событие popstate на странице загрузки, тогда как Firefox не делает этого.
         * */
        if (e.state) {
            OK.Layers.unregisterAll();
        }
    }
}

(function(window) {
    var STATE_ID_HEADER = 'X-StateId';
    var currentStateId = document.documentElement.getAttribute('data-initial-state-id');

    function setCurrentStateId(stateId) {
        currentStateId = stateId;
    }

    function processXhrResponse(xhr) {
        var stateId = xhr.getResponseHeader(STATE_ID_HEADER);
        if (stateId) {
            setCurrentStateId(stateId);
        }
    }

    function getCurrentStateId() {
        return currentStateId;
    }

    window.OK = window.OK || {};
    window.OK.state = {
        setCurrentStateId: setCurrentStateId,
        processXhrResponse: processXhrResponse,
        getCurrentStateId: getCurrentStateId
    };

    if (!window.OK.getCurrentDesktopModelId) {
        window.OK.getCurrentDesktopModelId = getCurrentStateId;
    }
})(window);
/**
 * Namespace для внешних методов, вызываемых из плеера.
 * @type {{}}
 */
OK.VideoPlayer = OK.VideoPlayer || {};

/**
 * Вызывается, когда пользователь нажимает на "Класс" в плеере. Используется в поплеере и в ленте.
 * @param {string} movieId
 */
OK.VideoPlayer.likeVideoFromFlash = function(movieId) {
    if (!window.navigateOnUrlFromJS) {
        // Anonymous page without GWT
        document.location.href = '/';
        return;
    }
    require(['jquery'], function($) {
        var $oldLikeWidget = $('.controls-list .controls-list_lk[data-l-id=' + movieId + ']:first'),
            $newLikeWidget = $('.widget-list .controls-list_lk[data-id1=' + movieId + ']:first');

        if (!$newLikeWidget.length) {
            // Скрытый виджет в ленте, отображается в новости типа "Добавлено N видео"
            $newLikeWidget = $('.widget .controls-list_lk[data-id1=' + movieId + ']:first');
        }

        $newLikeWidget.length && $newLikeWidget.data('clickFromVideo', true);

        $oldLikeWidget.length && $oldLikeWidget.click();
        $newLikeWidget.length && $newLikeWidget.click();
    });
};

/**
 * Вызывается, когда пользователь нажимает на "Подписаться/отписаться" в плеере. Используется в поплеере.
 * @param {{}} options
 * @param {string} albumId
 * @param {string} videoId
 * @param {string} action (либо "subscribe", либо "unsubscribe")
 * @param {function=} callback
 * @param {string=} activityId
 */
OK.VideoPlayer.toggleSubscriptionFromFlash = function(options, albumId, videoId, action, callback, activityId) {
    require(['OK/utils/utils'], function(utils) {
        var data = {
            'cmd': 'VideoSubscriptionBlock',
            'st.vvs.action': action,
            'st._aid': activityId
        };

        if (options.notifyMovieSubscription) {
            data['st.vvs.vid'] = videoId;
        } else {
            data['st.vvs.aid'] = albumId;
        }

        utils.ajax({
            url: '/dk',
            data: data
        }).done(utils.updateBlockModelCallback).done(callback);
    });
};

/**
 * Вызывается, когда пользователь отвечает на вопрос в аннотации в плеере. Используется в поплеере.
 * @param {number} movieId
 * @param {number} questionId
 * @param {number} answerId
 * @param {function=} callback
 */
OK.VideoPlayer.answerOnPoll = function(movieId, questionId, answerId, callback) {
    require(['OK/utils/utils'], function(utils) {
        utils.ajax({
            type: 'POST',
            url: '/web-api/annotations/poll/answer',
            data: {
                 movieId: movieId,
                questionId: questionId,
                answerId: answerId
            }
        }).done(callback);
    });
};

/**
 * Публикация видео ролика в ленте
 * @param {number} movieId
 * @param {function=} callback
 */
OK.VideoPlayer.reshare = function(movieId, callback) {
    require(['OK/utils/utils'], function(utils) {
        utils.ajax({
            type: 'GET',
            url: '/web-api/video/reshare/' + movieId
        }).done(callback);
    });
};

/**
 * Вызывается, когда пользователь нажимает на "Подписаться" в баннере подписки на группу.
 * @param {string} groupId
 * @param {function} callback
 */
OK.VideoPlayer.joinGroup = function(groupId, callback) {
    require(['OK/utils/utils'], function(utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'videoCommand',
                'a': 'joinGroup',
                'gid': groupId
            }
        }).done(callback).fail(callback);
    });
};

/**
 * Вызывается, когда пользователь нажимает на "Подписаться" в аннотации профиля.
 * @param {string} profileId
 * @param {function} callback
 */
OK.VideoPlayer.subscribeProfile = function(profileId, callback) {
    require(['OK/utils/utils'], function(utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'videoCommand',
                'a': 'subscribeProfile',
                'pid': profileId
            }
        }).done(callback).fail(callback);
    });
};

/**
 * Открывает страницу, на которой можно загрузить видео.
 */
OK.VideoPlayer.OKVideoOpenUserUpload = function() {
    window.navigateOnUrlFromJS ?
        window.navigateOnUrlFromJS('/video/myVideo') :
        document.location.href = '/video/myVideo';
};

/**
 * Запоминает видео просмотренным.
 * @param {number} watchedId
 */
OK.VideoPlayer.addWatched = function(watchedId) {
    watchedId = parseInt(watchedId, 10);
    OK.VideoPlayer.storage.addWatched(watchedId);
};


OK.VideoPlayer.openProduct = function(movieId, product) {
    require(['OK/utils/vanilla'], function(vanilla) {
        var requestData;
        if (product.groupId != null) {
            requestData = {
                'cmd': 'PopLayerMediaTopicOpen',
                'st.mt.id': product.id,
                'st.mt.ot': 'GROUP_ADVERT',
                'st.cmd': 'altGroupAdvertsPage',
                'st.groupId': product.groupId
            }
        } else {
            requestData = {
                'cmd': 'PopLayer',
                'st.cmd': 'mall',
                'st.layer.cmd': 'PopLayerMallProduct',
                'st.layer.productId': product.id,
                'st.layer.section': product.section,
                'st.layer.ePT': product.campaignId || 'cn:shoppingtv'
            }
        }
        vanilla.ajax({
            url: '/dk',
            data : requestData
        }).then(function (data) {
            vanilla.updateBlockModelCallback(data);
        });
    });
};

OK.VideoPlayer.openLogin = function() {
    require(['OK/AuthLoginPopup'], function (authLoginPopupModule) {
        authLoginPopupModule.open({
                href: decodeURIComponent(OK.historyManager.getState())
            }
        );
    });
};

/**
 * Хранилище.
 */
OK.VideoPlayer.storage = (function() {
    /**
     * Prefix for LocalStorage keys.
     * @type {string}
     */
    var LS_PREFIX = '_vp_',
        /**
         * Size of watched movies cache
         */
        WATCHED_CACHE_SIZE = 1000,
        /**
         * Size of last playing cache
         */
        LAST_PLAYING_TIME_CACHE_SIZE = 500;

    /**
     * LocalStorage reference.
     * @type {Storage}
     */
    var storage = (function() {
        var id = new Date, st, res;
        try {
            (st = window.localStorage).setItem(id, id);
            res = st.getItem(id) == id;
            st.removeItem(id);
            return res && st;
        } catch (exception) {}
    }());

    /**
     * Get item from storage.
     * @param {string} name
     * @return {string|number|[]|boolean}
     * @private
     */
    function _getItem(name) {
        try {
            return storage && JSON.parse(storage.getItem(LS_PREFIX + name));
        } catch (e) {
            return null;
        }
    }

    /**
     * Set item to storage.
     * @param {string} name
     * @param {string|number|{}|[]} value
     * @private
     */
    function _setItem(name, value) {
        try {
            storage && storage.setItem(LS_PREFIX + name, JSON.stringify(value));
        } catch (e) {
            // Переполнение хранилища или нет доступа не запись
        }
    }

    /**
     * Remove item from storage.
     * @param {string} name
     * @private
     */
    function _removeItem(name) {
        storage && storage.removeItem(LS_PREFIX + name);
    }

    /**
     * Get storage size.
     * @return {number}
     * @private
     */
    function _getLength() {
        return storage && storage.length;
    }

    /**
     * @param {[]} array
     * @param {number} limit
     * @param {*} elementToAdd
     * @private
     */
    function _addToCircularArray(array, limit, elementToAdd) {
        array.push(elementToAdd);
        while (array.length > limit) {
            array.shift();
        }

        return array;
    }

    /**
     * Current volume.
     * @type {number}
     */
    var volume = _getItem('volume');

    /**
     * Last used quality name.
     * @type {string}
     */
    var lastVideoQualityName = _getItem('lastVideoQualityName');

    /**
     * Client id.
     * @type {string}
     */
    var cid = _getItem('cid');

    /**
     * Last used network speed.
     * @type {number}
     */
    var lastSpeed = _getItem('lastSpeed');

    /**
     * Time when last video viewed.
     * @type {string}
     */
    var lastVideoShowTime = _getItem('lastVideoShowTime');

    /**
     * Last shown video in day.
     * @type {number}
     */
    var lastDayVideoShown = _getItem('lastDayVideoShown');

    /**
     * Last shown ad in day.
     * @type {number}
     */
    var lastDayAdvShown = _getItem('lastDayAdvShown');

    /**
     * Stop times for movies.
     * @type {{id: number, time: number}[]}
     */
    var movielastPlayingTime = _getItem('movielastPlayingTime') || [];

    /**
     * Cache for already watched movies.
     * @type {[]}
     */
    var watchedMoviesCache = _getItem('watchedMoviesCache') || [];

    /**
     * Switcher for autoplay.
     * @type {boolean}
     */
    var autoplayEnabled = _getItem('autoplayEnabled');

    /**
     * Switcher for cinema mode.
     * @type {boolean}
     */
    var cinemaModeEnabled = _getItem('cinemaModeEnabled');

    /**
     * Mini player width.
     * @type {number}
     */
    var miniPlayerWidth = _getItem('miniPlayerWidth');

    /**
     * Mini player top position.
     * @type {number}
     */
    var miniPlayerTop = _getItem('miniPlayerTop');

    /**
     * Mini player left position.
     * @type {number}
     */
    var miniPlayerLeft = _getItem('miniPlayerLeft');


    return {
        getVolume: function() {
            return volume;
        },
        setVolume: function(value) {
            _setItem('volume', volume = value);
        },
        getLastVideoQualityName: function() {
            return lastVideoQualityName;
        },
        setLastVideoQualityName: function(value) {
            _setItem('lastVideoQualityName', lastVideoQualityName = value);
        },
        getCid: function() {
            return cid;
        },
        setCid: function(value) {
            _setItem('cid', cid = value);
        },
        getLastSpeed: function() {
            return lastSpeed;
        },
        setLastSpeed: function(value) {
            _setItem('lastSpeed', lastSpeed = value);
        },
        getLastVideoShowTime: function() {
            return lastVideoShowTime;
        },
        setLastVideoShowTime: function(value) {
            _setItem('lastVideoShowTime', lastVideoShowTime = value);
        },
        getLastDayVideoShown: function() {
            return lastDayVideoShown;
        },
        setLastDayVideoShown: function(value) {
            _setItem('lastDayVideoShown', lastDayVideoShown = value);
        },
        getLastDayAdvShown: function() {
            return lastDayAdvShown;
        },
        setLastDayAdvShown: function(value) {
            _setItem('lastDayAdvShown', lastDayAdvShown = value);
        },
        getMovieLastPlayingTime: function(movieId) {
            movielastPlayingTime = Array.isArray(movielastPlayingTime) ? movielastPlayingTime : [];
            movieId = parseInt(movieId, 10);
            for (var i = 0, l = movielastPlayingTime.length; i < l; i++) {
                if (movielastPlayingTime[i].id === movieId) {
                    return movielastPlayingTime[i].time;
                }
            }
            return 0;
        },
        setMovieLastPlayingTime: function(movieId, time) {
            movielastPlayingTime = Array.isArray(movielastPlayingTime) ? movielastPlayingTime : [];
            movieId = parseInt(movieId, 10);
            var timeObject = {id: movieId, time: time};

            for (var i = 0, l = movielastPlayingTime.length; i < l; i++) {
                if (movielastPlayingTime[i].id === movieId) {
                    movielastPlayingTime.splice(i, 1);
                    break;
                }
            }
            _setItem('movielastPlayingTime', _addToCircularArray(movielastPlayingTime, LAST_PLAYING_TIME_CACHE_SIZE, timeObject));
        },
        isWatched: function(movieId) {
            return watchedMoviesCache.indexOf(movieId) != -1;
        },
        addWatched: function(watchedId) {
            _setItem('watchedMoviesCache', _addToCircularArray(watchedMoviesCache, WATCHED_CACHE_SIZE, watchedId));
        },
        getAlreadySeenSimilar: function(count) {
            return watchedMoviesCache.slice(-count);
        },
        isAutoplayEnabled: function() {
            return autoplayEnabled !== false;
        },
        setAutoplayEnabled: function(value) {
            _setItem('autoplayEnabled', autoplayEnabled = value);
        },
        isCinemaModeEnabled: function() {
            return !!cinemaModeEnabled;
        },
        setCinemaModeEnabled: function(value) {
            _setItem('cinemaModeEnabled', cinemaModeEnabled = value);
        },
        getMiniPlayerWidth: function() {
            return miniPlayerWidth;
        },
        setMiniPlayerWidth: function(value) {
            _setItem('miniPlayerWidth', miniPlayerWidth = value);
        },
        getMiniPlayerTop: function() {
            return miniPlayerTop;
        },
        setMiniPlayerTop: function(value) {
            _setItem('miniPlayerTop', miniPlayerTop = value);
        },
        getMiniPlayerLeft: function() {
            return miniPlayerLeft;
        },
        setMiniPlayerLeft: function(value) {
            _setItem('miniPlayerLeft', miniPlayerLeft = value);
        }
    };
})();


/**
 * Called then user clicks on class button in flash player, used on main video page and in feeds
 * @param {string} searchBlock
 * @deprecated use OK.VideoPlayer.likeVideoFromFlash()
 */
function likeVideoFromFlash(searchBlock) {
    if (typeof navigateOnUrlFromJS != 'function') {
        // это анонимная страница, без GWT
        document.location.href = '/';
        return;
    }
    var block = $('#' + searchBlock), sp, url, el, likeParams;
    for (;;) {
        sp = block.find('.klass_w');
        if (sp.length > 0) {
            break;
        }
        block = block.parent();
        if (block.length == 0) {
            return;
        }
    }

    el = sp.find('a.controls-list_lk')[0];
    if (el != null) { // TODO после запуска эксперимента синх. лайков оставить только "span"
        url = el.href;
    } else {
        el = sp.find('span.controls-list_lk')[0];
        if (el != null) {
            url = el.getAttribute('data-href');
            likeParams = el.getAttribute('data-l-p');
            if (likeParams) {
                url += '&st.v.cl=' + likeParams; // добавим ключ перерисовки (см. /one/app/community/dk/gwt/hook/client/hooks/vote/VoteHook.java:23)
            }
        }
    }

    if (url) {
        navigateOnUrlFromJS(url + '&st.v.ff=on');
    }
}

/**
 * вызывается флешкой - нужно открыть страницу, где можно закачать видео
 * @deprecated Use OK.VideoPlayer.OKVideoOpenUserUpload()
 */
function OKVideoOpenUserUpload() {
    OK.VideoPlayer.OKVideoOpenUserUpload();
}


function runLinkedVideoCallbackFromJS(params) {
    likeCallbackFromJs(params.callbackId + 'Object', params.liked, params.likeCount);
    likeCallbackFromJs(params.callbackId + 'E', params.liked, params.likeCount);
    likeCallbackFromJs('VideoPopup_player_' + params.callbackId + 'Object', params.liked, params.likeCount);
}

/**
 * Called from inline javascript generated by InternalLikeBlock. Called then user clicks on DHTML class button
 * @param {{}} params
 */
function runLinkedVideoCallback(params) {
    var child = document.getElementById('mc' + params.blockId);
    child.parentNode.removeChild(child);
    disableKlassByPlayerId(params.callbackId + 'Object') ||
    disableKlassByPlayerId(params.callbackId + 'E') ||
    disableKlassByPlayerId('VideoPopup_player_' + params.callbackId + 'Object');
}

/**
 * Called from inline javascript generated by InternalLikeBlock. Called then user clicks on DHTML class button
 * @param {{}} params
 */
function runLinkedVideoCallbackU(params) {
    var child = document.getElementById('mc' + params.blockId);
    child.parentNode.removeChild(child);
    runLinkedVideoCallbackFromJS(params);

    // В леере медиатопика нужно классы видео синхронизировать с классами медиатопика.
    require(['jquery'], function($) {
        var $markers = $('.js-like-marker[data-l-id=' + params.resourceId + ']');
        $markers.each(function() {
            var blockHookId = OK.hookModel.getNearestBlockHookId(this),
                $blockHook = $('#hook_Block_' + blockHookId);
            if ($blockHook.length) {
                likeCallbackFromJs($blockHook.data('playerId') + 'E', params.liked, params.likeCount);
            }
        });
    });
}

/**
 * Disable class button in flash player by player ID. Called then user clicks on DHTML class button
 * @param {string} playerId
 */
function disableKlassByPlayerId(playerId) {
    try {
        document.getElementById(playerId).disableKlassFromJs();
    } catch (e) {
        // Method is not defined. Older version of player.
    }
}

/**
 * Disable/enable class button in flash player by player ID. Called when user clicks on DHTML class button
 * @param {string} playerId
 * @param {boolean} liked
 * @param {number} likeCount
 */
function likeCallbackFromJs(playerId, liked, likeCount) {
    try {
        document.getElementById(playerId).likeCallbackFromJs(liked, likeCount);
    } catch (e) {
        // Method is not defined. Older version of player.
    }
}


/**
 * Ошибка загрузки картинки на видео-витрине
 * @param {HTMLElement} img
 */
OK.VVImageError = function(img) {
    img.parentNode.removeChild(img);
};

/**
 * Плеер дергает эту функцию при закрытие фулл-скрин режима, чтобы обновить все данные на страничке
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 */
OK.VideoPlayer.playMovie = function(movieId, albumId, autoplayType, movieIndex) {
    require(['OK/VideoAutoplayLayer'], function(VideoAutoplayLayer) {
        VideoAutoplayLayer.playMovie(movieId, albumId, autoplayType, movieIndex);
    });
};

/**
 * Открыть ролик по movieId
 * @param {string} movieId
 * @param {string=} aid
 * @param {string=} albumId
 * @param {string=} baseUrl
 * @param {string=} playlistIds
 * @param {string=} cmd
 * @param {boolean=} inMini
 * @param {string=} movieBlockId
 */
OK.VideoPlayer.openMovie = function(movieId, aid, albumId, baseUrl, playlistIds, cmd, inMini, movieBlockId) {
    OK.Layers.startLoad();
    require(['OK/VideoAutoplayLayer'], function(VideoAutoplayLayer) {
        var data = {'st.vpl.id': movieId};
        if (aid) {
            data['st._aid'] = aid;
        }
        if (cmd) {
            data['st.cmd'] = cmd;
        }
        if (albumId) {
            data['st.vpl.vs'] = 'album';
            data['st.vpl.albumId'] = albumId;
        }
        if (baseUrl) {
            data['st.vpl.bu'] = baseUrl;
        }
        if (playlistIds) {
            data['st.vpl.vs'] = 'collage';
            data['st.vpl.vp'] = playlistIds;
        }
        if (inMini) {
            data['st.vpl.mini'] = true;
        }
        if (movieBlockId) {
            data['st.vpl.fmbid'] = movieBlockId;
        }
        if (!albumId && !playlistIds) {
            data['st.vpl.dla'] = true;
        }
        VideoAutoplayLayer.navigateToMovie('/dk?cmd=PopLayerVideo', data, movieId, true);
    });
};

/**
 * Открыть ролик по shareId
 * @param {string} shareId
 * @param {string=} aid
 */
OK.VideoPlayer.openShare = function(shareId, aid) {
    require(['OK/VideoAutoplayLayer'], function(VideoAutoplayLayer) {
        var data = {'st.vpl.sid': shareId};
        if (aid) {
            data['st._aid'] = aid;
        }
        VideoAutoplayLayer.navigateToMovie('/dk?cmd=PopLayerVideo', data, shareId, true);
    });
};

/**
 * Плеер дергает эту функцию, чтобы проиграть след. видео по списку
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 * @param {function=} callback
 * @param {boolean=} isAuto
 */
OK.VideoPlayer.playNextMovie = function(movieId, albumId, autoplayType, movieIndex, callback, isAuto) {
    require(['OK/VideoAutoplayLayer'], function(VideoAutoplayLayer) {
        VideoAutoplayLayer.playNextMovie(movieId, albumId, autoplayType, movieIndex, callback, isAuto);
    });
};

/**
 * Плеер дергает эту функцию для запроса след. метаданных при автопросмотре в фулл-скрине
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 * @param {function=} callback
 */
OK.VideoPlayer.getNextMetadata = function(movieId, albumId, autoplayType, movieIndex, callback) {
    require(['OK/VideoAutoplayLayer'], function(VideoAutoplayLayer) {
        VideoAutoplayLayer.getNextMetadata(movieId, albumId, autoplayType, movieIndex, false, callback);
    });
};

/**
 * Плеер дергает эту функцию для запроса данных о след. ролике при автопросмотре
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 * @param {function=} callback
 */
OK.VideoPlayer.getNextMetadataSmall = function(movieId, albumId, autoplayType, movieIndex, callback) {
    require(['OK/VideoAutoplayLayer'], function(VideoAutoplayLayer) {
        VideoAutoplayLayer.getNextMetadata(movieId, albumId, autoplayType, movieIndex, true, callback);
    });
};

/**
 * Плеер дергает эту функцию, чтобы развернуть леер в cinema-mode
 * @param {?string} link
 * @param {?string} id
 */
OK.VideoPlayer.toggleCinemaMode = function(link, id) {
    if (!id && link) {
        // Для старого плеера, в котором не передается id
        id = link.slice(link.lastIndexOf('/') + 1).replace(/(\?|&).*/, '');
    }

    if (!id) {
        require(['OK/VideoAutoplayLayer'], function(VideoAutoplayLayer) {
            OK.VideoPlayer.storage.setCinemaModeEnabled(VideoAutoplayLayer.toggleCinemaMode(true));
        });
    } else {
        var aid = 'Open_Layer_From_Player';
        if (/^[0-9]+$/.test(id)) {
            require(['OK/utils/vanilla'], function (vanilla) {
                var params = vanilla.extractParams(vanilla.unescapeXml(link));
                var movieBlockId = params["st.vpl.fmbid"];
                OK.VideoPlayer.openMovie(id, aid, undefined, undefined, undefined, undefined, undefined, movieBlockId);
            });
        } else {
            OK.VideoPlayer.openShare(id, aid);
        }
    }
    OK.VideoPlayer.closePopLayer();
    OK.logger.success('video.cinema', link ? 'middlesize' : 'toggle');
};

/**
 * Останавливает все плееры.
 */
OK.VideoPlayer.pauseAll = function() {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').pause();
    }
};

/**
 * Переинициализирует плеер.
 * @param {boolean=} isSmall
 * @param {boolean=} blockWebrtc
 */
OK.VideoPlayer.restart = function(isSmall, blockWebrtc) {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').retry(0, isSmall, blockWebrtc);
    }
};

/**
 * Останавливает все видеоплееры и схлопывает их обратно в карды.
 */
OK.VideoPlayer.stop = function() {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').stop();
    }
};

/**
 * Переопределяет и перерисовывает аннотации в ролике
 */
OK.VideoPlayer.changeAnnotations = function(annotations) {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').changeAnnotations(annotations);
    }
};

/**
 * Плеер дергает этот метод при старте воспроизведения.
 * @param {boolean} isMusic
 * @param {boolean} isLayer
 * @param {boolean} isFeed
 */
OK.VideoPlayer.onPlay = function(isMusic, isLayer, isFeed) {
    require(['OK/music2/app'], function (app) {
        if (!isMusic && !OK.VideoPlayer._isMusicPlaying) {
            // Если музыка играла, сохраним ID трека, чтобы при окончании воспроизведения видео запустить музыку снова
            if (app.playing()) {
                OK.VideoPlayer._isMusicPlaying = true;
                app.pause();
            }
        }
    });
};

/**
 * Плеер дергает этот метод при остановке воспроизведения.
 * @param {boolean} isMusic
 * @param {boolean} isLayer
 * @param {boolean} isFeed
 */
OK.VideoPlayer.onPause = function(isMusic, isLayer, isFeed) {
    // Запустим музыку снова, если она играла при старте воспроизведения видео
    require(['OK/music2/app'], function(app) {
        if (!isMusic && OK.VideoPlayer._isMusicPlaying) {
            OK.VideoPlayer._isMusicPlaying = false;
            app.play();
        }
    })
};

/**
 * Название события для подписки на события видео-плеера.
 * @see https://stash.odkl.ru/projects/ODKL/repos/odkl-videoplayer/browse/html5/js/abstract/Stats.js#119
 */
OK.VideoPlayer.EVENT_NAME = '__videoPlayerEvent';

/**
 * Плеер вызывает этот метод при возникновении события.
 * @param {string} type
 * @param {{}} data
 */
OK.VideoPlayer.onEvent = function(type, data) {
    require(['OK/utils/vanilla'], function(vanilla) {
        vanilla.trigger(document, OK.VideoPlayer.EVENT_NAME, {
            name: type,
            data: data
        });
    });
};

/**
 * Открывает форму фидбэка.
 * @param {string=} link
 */
OK.VideoPlayer.helpFeedback = function(link) {
    require(['OK/utils/utils'], function(utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'PopLayer',
                'st.layer.cmd': 'PopLayerHelpFeedback',
                'st.layer.categorynew': 'SITE_SECTION',
                'st.layer.subcategory': 'VIDEO_AND_MUSIC',
                'st.layer.categoryValue': 'VIDEO',
                'st.layer.origin': 'VIDEO_LAYER',
                'st.layer.badVideoLink': link || window.location.href
            }
        }).done(utils.updateBlockModelCallback);
    });
};

/**
 * Плеер вызывает этот метод для получения списка релейтедов.
 * @param {string} contentId
 * @param {string} provider
 * @param {function=} successCallback
 * @param {function=} errorCallback
 */
OK.VideoPlayer.getRelated = function(contentId, provider, successCallback, errorCallback) {
    require(['OK/utils/utils'], function(utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'videoRelatedCommand',
                'id': contentId,
                'count': 36
            }
        }).done(successCallback).fail(errorCallback);
    });
};

/**
 * Отправляет статистику эмбеда в Яндекс
 * @link https://yandex.ru/support/video/partners/jsapi.xml
 * @param {string} event Название события
 * @param {number=} param1
 * @param {number=} param2
 */
OK.VideoPlayer.yandexStat = function(event, param1, param2) {
    var data = {event: event};

    if (typeof param1 !== 'undefined') {
        switch (event) {
            case 'volumechange':
                data.volume = param1;
                break;
            default:
                data.time = param1;
        }
    }

    if (typeof param2 !== 'undefined') {
        switch (event) {
            case 'error':
                data.code = param2;
                break;
            case 'rewound':
                data.previousTime = param2;
                break;
            case 'adShown':
            case 'started':
            case 'timeupdate':
                data.duration = param2;
                break;
            case 'volumechange':
                data.muted = param2;
                break;
        }
    }
    window.parent.postMessage(data, '*');
};

/**
 * Плеер вызывает этот метод для добавление и удаление в альбом "посмотреть позже".
 * @param {number} movieId
 * @param {boolean} status
 * @param {function=} successCallback
 * @param {function=} errorCallback
 */
OK.VideoPlayer.watchLater = function(movieId, status, successCallback, errorCallback) {
    require(['OK/utils/utils'], function(utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'VideoWatchLaterBlock',
                'st.vvwl.action': status ? 'watchLater' : 'removeWatchLater',
                'st.vvwl.movieId': movieId
            }
        }).done(successCallback).fail(errorCallback);
    });
};

/**
 * Вызывается при ошибке видео (показе "печеньки")
 * @param {string} message Ключ сообщения об ошибке
 * @param {number=} time Позиция прогресс-бара в секундах
 */
OK.VideoPlayer.yandexError = function(message, time) {
    var code = 0;

    switch (message && message.toLowerCase().replace(/_/g, '')) {
        // Недоступное видео
        case 'movienotready':
        case 'uploading':
        case 'creating':
        case 'processing':
        case 'offline':
        case 'livenotstarted':
        case 'liveended':
        case 'onmoderation':
            code = 100; // Прочие случаи недоступного видео.
            break;
        case 'moviedeleted':
        case 'groupnotfound':
        case 'usernotfound':
            code = 101; // Видео удалено.
            break;
        case 'blocked':
        case 'censored':
            code = 102; // Видеоролик или учетная запись заблокирована.
            break;
        case 'notfound':
            code = 103; // Видеоролик не существует либо URL не поддерживается.
            break;

        // Ограничение доступа к видеоролику
        case 'copyrightsrestricted':
            code = 150; // Прочие ограничения просмотра видео.
            break;
        case 'accessdenied':
        case 'groupaccessdenied':
        case 'closedprofile':
        case 'limitedaccess':
        case 'movienoaccess':
        case 'weberrornoaccess':
            code = 151; // Недостаточно прав для просмотра видео.
            break;
        case 'videoembeddisabled':
        case 'videoembeddisabledpartner':
            code = 152; // Видео запрещено к проигрыванию на других сайтах.
            break;
        case 'videoembeddisabledcountry':
        case 'movieunavailableforregion':
            code = 153; // Видео запрещено к проигрыванию в данном регионе.
            break;

        // Прочее
        case 'player':
            code = 5; // Сбой работы плеера (ошибки воспроизведения HTML-проигрывателя и др.).
            break;
        default:
            code = 0; // Прочие ошибки.
            break;
    }

    OK.VideoPlayer.yandexStat('error', time || 0, code);
};

OK.VideoPlayer.closePopLayer = function() {
    OK.hookModel.setHookContent('PopLayer', '');
};

/**
 * Трекаем события из плеера через MyTracker
 * @param {string} name
 * @param {?{}} params
 */
OK.VideoPlayer.pushTmrStat = function(name, params) {
    require(['OK/metrics/MyTrackerService'], function(tmr) {
        tmr.myTrackerService.trackCustomGoal(name, {
            params
        });
    });
};

